import {defineStore} from 'pinia'
import {useCookie} from "nuxt/app";
import {useStartupStore} from "~/store/sturtupStore.js";
import {useCurrencyStore} from "~/store/currencyStore.js";
import {useClientFetch} from "~/utils/useClientFetch.js";

export const useCartStore = defineStore('cart', {
    state: () => ({
        content: {},
        couponResponse: null,
        cartActive: false,
        initialized: false,
        serviceLocations: [],
        sources: {
            product_page_shop_the_look: 'product_page_shop_the_look',
            product_page_add_to_cart_button: 'product_page_add_to_cart_button',
            product_page_add_to_cart_fixed_button: 'product_page_add_to_cart_button',
            product_page_sample_button: 'product_page_sample_button',
            better_together_calculator: 'better_together_calculator',
            better_together_combo: 'better_together_combo',
            collection_page: 'collection_page',
            share_cart: 'share_cart',
            alternative_sample_main: 'alternative_sample_main',
            alternative_sample_alternative: 'alternative_sample_alternative',
            fix_fog_calculator: 'fix_fog_calculator',
            product_category_faq: 'product_category_faq',
        }
    }),
    getters: {
        cartIsActive: (state) => {
            return state.cartActive
        },
        getToken() {
            return this.getCartToken()
        },
    },
    actions: {
        getCartToken() {
            if (typeof localStorage === 'undefined') {
                return
            }

            let token = localStorage?.getItem('cart_token')

            if (!token) {
                token = useCookie('cart_token').value

                if (token) {
                    this.setCartToken(token)
                }
            }

            // setTimeout(() => {
            //     let token = getData('cart_token')
            //     console.log(token)
            // }, 3000)

            return token
        },
        setCartToken(token) {
            if (token) {
                localStorage?.setItem('cart_token', token)

                useCookie('cart_token', {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365 * 10,
                    sameSite: 'Lax'
                }).value = token
            }
        },
        randString(length) {
            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * chars.length);
                result += chars[randomIndex];
            }
            return result;
        },
        openCart() {
            this.cartActive = true

            if (typeof (dataLayer) != "undefined" && dataLayer !== null) {
                let items = this.content.products?.map(product => {
                    return {
                        item_name: product.attributes.sku + ' - ' + product.name,
                        item_id: product.attributes.sku,
                        price: product.price,
                        item_brand: product.attributes.supplier_name,
                        item_category: product.attributes.group_category,
                        quantity: product.quantity
                    }
                })

                dataLayer.push({ecommerce: null});
                dataLayer.push({
                    event: "view_cart",
                    ecommerce: {
                        items: items
                    }
                });
            }
        },
        toggleCart() {
            this.cartActive = !this.cartActive
        },
        getContent(hash, type, callback = null) {
            let token = this.getCartToken();

            if (hash && !token) {
                token = this.randString(25);
                this.setCartToken(token);
            }

            const startTime = Date.now(); // Start time

            return useClientFetch('/cart', {
                query: {cart_token: token, hash, type},
            }).then(response => {
                this.content = response;
                this.initialized = true;

                if (!token) {
                    this.setCartToken(response.token);
                }

                const endTime = Date.now(); //Finish time
                const duration = endTime - startTime;
                console.log(`Successful request  ${duration} ms`);

                if (callback) {
                    callback(response);
                }
            })
        },
        addItemsToCart(items) {
            const token = this.getCartToken()

            useClientFetch('/cart/add-many', {
                method: 'POST',
                body: {
                    items: items,
                    cart_token: token
                }
            }).then(response => {
                this.content = response
                const items = this.content.items

                if (!token) {
                    this.setCartToken(response.token)
                }

                if (items?.length && typeof (dataLayer) != "undefined" && dataLayer !== null) {
                    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                    dataLayer.push({
                        'event': 'addToCart',
                        'ecommerce': {
                            'currencyCode': '' + useCurrencyStore().currency,
                            'add': {
                                'products': items.map(item => (
                                    {
                                        'name': item.name,
                                        'id': item.sku,
                                        'price': item.price,
                                        'brand': item.brand,
                                        'category': item.category,
                                        'quantity': item.quantity,
                                        'item_list_id': item.source,
                                        'item_list_name': item.source
                                    }
                                ))
                            }
                        }
                    });

                    dataLayer.push({ecommerce: null});
                    dataLayer.push({
                        'event': 'add_to_cart',
                        'ecommerce': {
                            'currency': useCurrencyStore().currency,
                            'value': Math.round(items.reduce((acc, item) => acc + item.price * item.quantity, 0) * 100) / 100,
                            'items': items.map(item => ({
                                'item_id': item.sku,
                                'item_name': item.name,
                                'item_brand': item.brand,
                                'item_category': item.category,
                                'item_category2': item.category2,
                                'item_category3': item.category3,
                                'currency': useCurrencyStore().currency,
                                'price': item.price,
                                'quantity': item.quantity,
                                'item_list_id': this.sources.better_together_calculator,
                                'item_list_name': this.sources.better_together_calculator
                            }))
                        }
                    });
                }

                this.openCart()
            });
        },
        addItemToCart({id, count, sample, alternative = false, source}) {
            const token = this.getCartToken()

            useClientFetch('/cart/add', {
                method: 'POST',
                query: {
                    product_id: id,
                    count: count,
                    sample: sample,
                    alternative: alternative ? 1 : 0,
                    source: source,
                    cart_token: token
                }
            }).then(response => {
                this.content = response
                const item = this.content.item

                if (!token) {
                    this.setCartToken(response.token)
                }

                this.openCart()

                if (item && typeof (dataLayer) != "undefined" && dataLayer !== null) {
                    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                    dataLayer.push({
                        'event': 'addToCart',
                        'ecommerce': {
                            'currencyCode': '' + useCurrencyStore().currency,
                            'add': {                                // 'add' actionFieldObject measures.
                                'products': [{                        //  adding a product to a shopping cart.
                                    'name': item.name,
                                    'id': item.sku,
                                    'price': item.price,
                                    'brand': item.brand,
                                    'category': item.category,
                                    'quantity': item.quantity,
                                    'item_list_id': source,
                                    'item_list_name': source,
                                }]
                            }
                        }
                    });

                    dataLayer.push({ecommerce: null});
                    dataLayer.push({
                        'event': 'add_to_cart',
                        'ecommerce': {
                            'currency': useCurrencyStore().currency,
                            'value': Math.round(item.price * item.quantity * 100) / 100,
                            'items': [{
                                'item_id': item.sku,
                                'item_name': item.name,
                                'item_brand': item.brand,
                                'item_category': item.category,
                                'item_category2': item.category2,
                                'item_category3': item.category3,
                                'currency': useCurrencyStore().currency,
                                'price': item.price,
                                'quantity': item.quantity,
                                'item_list_id': source,
                                'item_list_name': source,
                            }]
                        }
                    });
                }

                if (this.content.klaviyo_data) {
                    var _learnq = window._learnq;

                    if (_learnq) {
                        _learnq.push(["track", "Added to Cart", this.content.klaviyo_data]);
                    }
                }
            });
        },
        updateItem(id, count) {
            const token = this.getCartToken()

            useClientFetch('/cart/update', {
                method: 'POST',
                query: {
                    product_id: id,
                    count,
                    cart_token: token
                }
            }).then(response => {
                this.content = response
            });
        },
        removeItem(id, is_sample) {
            const token = this.getCartToken()
            this.content.products = this.content.products.filter(product => product.id !== id)

            useClientFetch('/cart/remove', {
                method: 'POST',
                query: {
                    product_id: id,
                    sample: is_sample,
                    cart_token: token
                }
            }).then(response => {
                this.content = response

                if (this.content.removed_item) {
                    dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                    dataLayer.push({
                        'event': 'remove_from_cart',
                        'ecommerce': {
                            'items': [this.content.removed_item]
                        }
                    });
                }
            });
        },
        saveEmail(email) {
            const token = this.getCartToken()

            useClientFetch('/cart/save-email', {
                method: 'POST',
                query: {
                    email,
                    cart_token: token
                }
            }).then(response => {
                this.content = response

                if (email && window._learnq) {
                    window._learnq.push(['identify', {
                        '$email': this.content.email,
                        '$first_name': this.content.first_name,
                        '$last_name': this.content.second_name
                    }]);

                    let data = {
                        "$event_id": this.randString(25),
                        "$value": this.content.total_sum,
                        "ItemNames": this.content.products.map(item => item.name),
                        "CheckoutURL": this.content.share_cart_url,
                        "Categories": [],
                        "Items": this.content.products.map(item => {
                            return {
                                'ProductID': item.attributes.sku,
                                'SKU': item.attributes.sku,
                                'ProductName': item.name,
                                'Quantity': item.quantity,
                                'ItemPrice': item.price,
                                'RowTotal': item.sub_total,
                                'ProductURL': item.attributes.url,
                                'ImageURL': item.attributes.image.replaceAll(' ', '%20'),
                                'ProductCategories': item.attributes.category
                            }
                        })
                    };

                    window._learnq.push(["track", "Started Checkout", data]);
                }
            });
        },
        saveClientData(key, value) {
            const token = this.getCartToken()

            useClientFetch('/cart/save-client-data', {
                method: 'POST',
                query: {
                    key,
                    value,
                    cart_token: token
                }
            }).then(response => {
                this.content = response
            });
        },
        saveShipping(key) {
            const token = this.getCartToken()

            useClientFetch('/cart/save-shipping', {
                method: 'POST',
                query: {
                    key,
                    cart_token: token
                }
            }).then(response => {
                this.content = response

                if (key === 'parcel_ombud') {
                    this.getServiceLocations()
                }

                if (key !== 'pick_up') {
                    this.saveClientData('truck_pick_up', 0)
                }
            });
        },
        saveCoupon(coupon) {
            const token = this.getCartToken()

            useClientFetch('/cart/save-coupon', {
                method: 'POST',
                query: {
                    coupon,
                    cart_token: token
                }
            }).then(response => {
                if (response.status === 'ok') {
                    this.content = response.data
                }

                this.couponResponse = response.message

                setTimeout(() => {
                    this.couponResponse = null
                }, 3000)
            });
        },
        removeCoupon() {
            const token = this.getCartToken()

            useClientFetch('/cart/remove-coupon', {
                method: 'POST',
                query: {
                    cart_token: token
                }
            }).then(response => {
                this.content = response
            });
        },
        share(email) {
            const token = this.getCartToken()

            useClientFetch('/cart/share', {
                method: 'POST',
                query: {
                    email,
                    cart_token: token
                }
            });
        },
        getServiceLocations() {
            if (this.content.shipping_code !== "parcel_ombud") {
                return;
            }

            const startupStore = useStartupStore()

            const country_code = startupStore.countries.find(country => country.id == this.content.client_data.country).code;

            useClientFetch('/cart/service-points', {
                method: 'POST',
                query: {
                    country: country_code,
                    zip: this.content.client_data.zip,
                }
            }).then(response => {
                this.serviceLocations = response.service_points;

                if (!this.content.client_data.servicePoint && this.serviceLocations.length) {
                    if (!this.content.client_data.servicePoint) {
                        this.saveServicePoint(this.serviceLocations[0])
                    }
                }
            });
        },
        saveServicePoint(servicePoint) {
            this.saveClientData('servicePoint', JSON.stringify(servicePoint))
        },
        isReadyForPayment() {
            const formIsValid =
                this.content.email
                && this.content.client_data.first_name
                && this.content.client_data.second_name
                && this.content.client_data.phone
                && this.content.client_data.address
                && this.content.client_data.zip
                && this.content.client_data.country
                && this.content.shipping_code

            if (!formIsValid) {
                return false;
            }

            if (this.content.client_data.type === 'organization') {
                return this.content.client_data.company_name && this.content.client_data.company_number;
            }

            return true;
        }
    },
})
