<template>
  <component :class="[type, {underline:underline}, {uppercase}]" :is="tag"
             :style="{fontSize : fontSize ? fontSize : false, fontWeight, textAlign:textAlignment, height}">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'p'
    },
    fontSize: {
      type: String,
    },
    fontWeight: {
      type: String,
    },
    type: {
      type: String,
    },
    textAlignment: {
      type: String,
    },
    height: {
      type: String,
    },
    align: {
      type: String,
    },
    underline: {
      type: Boolean,
    },
    uppercase: {
      type: Boolean,
    },
  },
  components: {}
}
</script>

<style scoped lang="scss">
.red {
  color: $red;
}

.underline {
  text-decoration: underline;
}
</style>
