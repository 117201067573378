<script setup>
import Cart from "~/components/organisms/Cart.vue";
import {useCustomFetch} from "~/composables/customFetch.js";
import {useStartupStore} from "~/store/sturtupStore";
import {useUserStore} from "~/store/userStore.js";
import {useRoute} from "vue-router";
import {useCookie} from "nuxt/app";

const route = useRoute();
const userStore = useUserStore();
const startupStore = useStartupStore();

const config = useRuntimeConfig().public
const {finish: finishNuxtLoading} = useLoadingIndicator()

finishNuxtLoading()
if (process.client) {
  finishNuxtLoading()
}

const {data} = await useCustomFetch('startup')

const {
  header_right,
  header_left,
  side_menu,
  top_menu,
  side_menu_collections,
  main_menu_footer,
} = data.value.menu

startupStore.countries = data.value.countries
startupStore.header_left = header_left
startupStore.header_right = header_right
startupStore.side_menu = side_menu
startupStore.top_menu = top_menu
startupStore.side_menu_collections = side_menu_collections
startupStore.main_menu_footer = main_menu_footer
startupStore.campaign = data.value.campaign

if (data.value.user) {
  userStore.setUser(data.value.user)
}

onMounted(() => {
  console.log('App Mounted')

  // window.adrecord = function (order_id, order_value, coupon_code) {
  //   !(function (a, d, r, e, c) {
  //     adrecord = {
  //       "programID": 1002,
  //       "orderID": order_id,
  //       "orderValue": order_value,
  //       "couponCode": coupon_code
  //     };
  //     e = a.createElement(d);
  //     e.async = !0;
  //     e.src = r;
  //     c = a.getElementsByTagName(d)[0];
  //     c.parentNode.insertBefore(e, c);
  //   })(document, "script", "https://t.adii.io/convert.js");
  // }

  if (route.query.gclid) {
    const maxAge = {maxAge: 60 * 60 * 24 * 365}
    useCookie('hc_gclid', maxAge).value = route.query.gclid
    useCookie('hc_gad_source', maxAge).value = route.query.gad_source
    useCookie('hc_gbraid', maxAge).value = route.query.gbraid
    useCookie('hc_fo_s', maxAge).value = route.query.fo_s
  }

  window.addEventListener('CookiebotOnAccept', function (e) {
    console.log('Accept')
    if (Cookiebot.consent.marketing) {
      const consent = Cookiebot.consent

      let consentDate = new Date().toISOString(); // Поточна дата

      let consentData = {
        consentDate: consentDate,
        marketing: consent.marketing,
        statistics: consent.statistics,
        preferences: consent.preferences,
        necessary: consent.necessary,
        answer: 'accept'
      };

      localStorage.setItem("cookiebot_consent", JSON.stringify(consentData));
    }
  }, false);

  window.addEventListener('CookiebotOnDecline', function (e) {
    const consent = Cookiebot.consent

    let consentDate = new Date().toISOString(); // Поточна дата

    let consentData = {
      consentDate: consentDate,
      marketing: consent.marketing ?? false,
      statistics: consent.statistics ?? false,
      preferences: consent.preferences ?? false,
      necessary: consent.necessary ?? false,
      answer: 'decline'
    };

    localStorage.setItem("cookiebot_consent", JSON.stringify(consentData));
  }, false);
})

</script>

<template>
  <div>
    <noscript>
      <iframe :src="'https://www.googletagmanager.com/ns.html?id=' + config.GTM_ID"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>

    <NuxtLayout>
      <NuxtLoadingIndicator color="#fcbf00"/>
      <Cart/>

      <NuxtPage :keepalive="{max:4,  exclude: 'payment'}"/>
    </NuxtLayout>
  </div>
</template>
