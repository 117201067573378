<template>
  <button type="button" :class="[classes,type]" @click="click">
    <template v-if="type === 'plus'">
      <img src="/images/moves/plus.png"/>
    </template>
    <template v-else-if="type === 'minus'">
      <img src="/images/moves/minus.png"/>
    </template>
    <template v-else>
      <template v-if="$slots.default">
        <div class="row" style="margin: auto;">
          <template v-if="showChecked">
            <CheckIcon width="15px"/>
          </template>
          <template v-else>
            <slot/>
          </template>
          <SpinnerIcon class="spin" :class="{show:loading, always:alwaysShowSpinner}"/>
        </div>
      </template>
      <template v-else>
        <div class="row" style="margin: auto;">
          <p v-html="text"></p>
          <SpinnerIcon class="spin" :class="{show:loading, always:alwaysShowSpinner}"/>
        </div>
      </template>
      <small v-if="sub_text">{{ sub_text }}</small>
      <div class="help" v-if="help">
        <Help>{{ help }}</Help>
      </div>
    </template>
  </button>
</template>

<script>
import Help from "~/components/molecules/Help.vue";
import SpinnerIcon from "~/components/icons/SpinnerIcon.vue";
import CheckIcon from "~/components/icons/CheckIcon.vue";

export default {
  components: {CheckIcon, SpinnerIcon, Help},
  props: {
    text: String,
    help: String,
    sub_text: String,
    loading: false,
    alwaysShowSpinner: false,
    type: {
      type: String,
      default: 'black'
    },
    checkAfterClick: false,
    timelessCheck: false
  },
  emits: ['click'],
  methods: {
    click(e) {
      if (this.showChecked) {
        return
      }

      if (this.checkAfterClick) {
        this.showChecked = true

        this.$emit('click', e)

        if (this.timelessCheck) {
          return
        }

        setTimeout(() => {
          this.showChecked = false
        }, 3000)
      } else {
        this.$emit('click', e)
      }
    }
  },
  data() {
    return {
      showChecked: false
    }
  },
  computed: {
    classes() {
      switch (this.type) {
        case 'plus':
          return 'round plus'
        case 'minus':
          return 'round minus'
        default:
          return this.type
      }
    }
  }
}
</script>

<style scoped lang="scss">
button {
  cursor: pointer;
  border: 0;
  position: relative;
  max-width: 100%;

  &.black {
    background: $space-black;
    color: $white;
    width: 101px;
    height: 35px;
    font-weight: 500;
    letter-spacing: 1.2px;
    border: none;
    cursor: pointer;

    @include smd {
      letter-spacing: 0.66px;
      font-size: 11px;
    }

    &:hover {
      background: $black;

      @include smd {
        background: $space-black;
      }
    }

    &:disabled {
      background: $disabled-bg;
      cursor: not-allowed;
      color: $disabled-text;

      &:deep(svg) {
        stroke: $light-black !important;
      }
    }

    svg {
      stroke: $white;
    }
  }

  &.grey {
    border-radius: 0;
    border: 0;
    height: 48px;
    font-weight: 600;
    display: flex;
    width: 288px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 10px auto auto;
    gap: 10px;
    background: $grey-green;

    &:hover {
      background: rgba(232, 222, 211, .9);

      @include smd {
        background: $grey-green;
      }
    }
  }

  &.light-grey {
    background: $grey;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      opacity: .8;
    }
  }

  &.round {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 0;
    align-items: center;
    display: flex;
    justify-content: center;

    &.minus {
      border: 1px solid $dark-black;
      background: $white;

      &:hover {
        background: hsla(0, 0%, 97.6%, .9);

        @include smd {
          background: $white;
        }
      }

      img {
        width: 10px;
      }
    }

    &.plus {
      background: $dark-black;

      &:hover {
        background: rgba(34, 34, 34, .8);

        @include smd {
          background: $dark-black;
        }
      }
    }


    img {
      width: 10px;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.half-tall {
    height: 42px;
  }

  &.short {
    height: 24px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.wide {
    width: 400px;
  }

  &.w-160 {
    width: 160px;
    font-size: 16px;
    letter-spacing: 0.95px;

    @include smd {
      width: 148px;
      font-size: 11px;
    }
  }

  &.w-175 {
    width: 175px;
  }

  &.mini-label {
    min-width: 94px;
    height: 32px;
    font-size: 12px;
    gap: 3px;
    background: $grey;

    &:hover {
      background: rgba(232, 222, 211, .9);

      @include smd {
        background: $grey;
      }
    }
  }

  &.bordered {
    height: 27px;
    font-size: 22px;
    background: transparent;
    border: 1.5px solid $dark-black;
    letter-spacing: 0.96px;

    &:hover {
      background: #E9EAF0;

      @include smd {
        background: transparent;
      }
    }
  }

  &.bronze {
    background: $bronze;
    color: $dark-black;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      background: rgba(232, 222, 211, .9);

      @include smd {
        background: $bronze;
      }
    }
  }

  &.coarse {
    font-weight: 500;
    font-size: 14px;
  }

  small {
    font-weight: 400;
    font-size: 11px;
  }

  &.tall {
    height: 56px;
  }

  &.middle {
    height: 48px;

    @include smd {
      height: 40px;
    }
  }

  .help {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 9;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.spin {
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  stroke: white;
  position: absolute;
  right: -24px;
  display: none;
  width: 16px;
  height: 16px;

  &.always {
    display: block;
  }

  &.show {
    display: block;
    animation-name: spin;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
