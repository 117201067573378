<script setup>
import Text from "~/components/atoms/Text.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";

const props = defineProps({
  active: {
    Boolean,
    default: false
  },
  title: {
    String,
    default: ''
  },
  position: {
    String,
    default: 'left'
  },
  width: {
    String,
  },
  type: {
    String,
    default: ''
  },
  bottom: {
    Boolean,
    default: false
  }
});

const active = ref(props.active);
const sideSliderActive = ref(props.active);
const container = ref(null);

watch(() => props.active, (val) => {
  if (val) {
    active.value = true;
    setTimeout(() => {
      sideSliderActive.value = true;
    }, 200);
  } else {
    sideSliderActive.value = false;

    setTimeout(() => {
      active.value = false;
    }, 200);
  }
});

const emit = defineEmits(['close', 'set-container-ref']);

onMounted(() => {
  if (container.value) {
    emit('set-container-ref', container.value);
  }
});

</script>

<template>
  <div class="side-slider full-width"
       :class="{active,right:position==='right',[props.type]:props.type, bottom:props.bottom}"
       @click="$emit('close')">
    <div ref="container" class="main-block" :style="{width:props.width}" :class="{active:sideSliderActive}" @click.stop>
      <div class="header row full-width space-between no-flex">
        <div class="row g-24">
          <Text tag="b" class="title">{{ title }}</Text>
          <slot name="title"/>
        </div>
        <CrossIcon class="close clickable" style="width: 28px; height: 28px;" @click="$emit('close')"/>
      </div>
      <div v-if="bottom" class="drug"></div>
      <slot/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.side-slider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999999;
  display: none;

  &.active {
    display: flex;
  }

  .drug {
    display: none;

    @include mobile {
      display: block;
      position: absolute;
      height: 3px;
      width: 50px;
      background: #e9e9e9;
      left: 50%;
      transform: translateX(-50%);
      top: 16px;
    }
  }

  .main-block {
    background: $white;
    box-shadow: 0 4px 15px 0 hsla(0, 0%, 65.5%, .1);
    z-index: 2;
    overflow: auto;
    transform: translateX(-100%);
    transition: 0.2s ease-in-out;
    max-width: 100%;
    width: 360px;

    @include smd {
      max-width: 86%;
    }
  }

  &.right {
    .main-block {
      right: 0;
      transform: translateX(100%);
      position: absolute;
      bottom: 0;
      top: 0;
    }
  }

  &.bottom {
    .main-block {
      @include mobile {
        transform: translateX(0%);
        top: 100%;
        border-radius: 25px 25px 0 0;
      }
    }
  }

  &.wide {
    .main-block {
      width: 480px;
    }
  }

  .main-block {
    &.active {
      transform: translateX(0);
    }
  }

  &.bottom {
    .main-block.active {
      @include mobile {
        top: 20%;
        border-radius: 25px 25px 0 0;
      }
    }

    .close {
      @include mobile {
        display: none;
      }
    }
  }

  .header {
    padding: 24px;

    @include smd {
      padding: 32px 24px;
    }
  }

  .title {
    font-size: 22px;
  }
}
</style>
