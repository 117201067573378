export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('page:finish', () => {
        const route = useRoute();
        const currentDomain = window.location.origin;

        let canonicalUrl = currentDomain + route.path;

        if (route.query.page) {
            canonicalUrl += `?page=${route.query.page}`;
        }

        useHead({
            link: [
                {
                    rel: 'canonical',
                    href: canonicalUrl
                }
            ]
        });
    });
});
