import {useUserStore} from "~/store/userStore.js";
import axios from "axios";
import * as Sentry from '@sentry/browser'

export const useClientFetch = (url, opts = {}) => {
    const config = useRuntimeConfig();

    if (!config || !config.public) {
        throw new Error('Runtime config is not available');
    }

    const userStore = useUserStore();

    const headers = {
        accept: 'application/json',
        referer: config.public.baseURL,
        'Accept-Language': config.public.locale,
        ...opts?.headers,
    };

    if (userStore.isAuthenticated) {
        headers['User-Token'] = `${userStore.token}`;
    }

    return axios({
        url,
        method: opts.method ?? 'GET',
        baseURL: config.public.API_URL,
        headers,
        params: opts.query,
        data: opts.body,
        timeout: 0,
    }).then((response) => {
        return response.data
    }).catch(err => {
        Sentry.captureException(err)
        throw err;
    })
};
