<script setup>
import CartProductItem from "~/components/molecules/CartProductItem.vue";
import {useCartStore} from "~/store/cartStore";

const store = useCartStore();
</script>

<template>
  <div class="products no-flex g-16 the-same-gap">
    <template v-for="product in store.content?.products">
      <CartProductItem
          :price="product.price"
          @click="$emit('click')"
          :isRestparti="product.attributes.lager_status === 'Restparti'"
          :link="'/shop/'+product.attributes.url"
          :is_sample="product.name.toLowerCase().includes('varuprov') || product.is_sample"
          @remove="store.removeItem(product.id,product.is_sample)"
          @plus="store.updateItem(product.id,parseInt(product.quantity)+1)"
          @minus="store.updateItem(product.id,parseInt(product.quantity)-(product.attributes.cart_multiplicity ?? 1))"
          v-model="product.quantity"
          @update="store.updateItem(product.id,$event)"
          :image="product.attributes.image" :quantity="product.quantity"
          :total="product.sub_total" :unit_per_box="product.attributes.unit_qty"
          :unit="product.attributes.unit" :name="product.name" :sku="product.attributes.sku">
        <slot :product="product"/>
      </CartProductItem>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>
