import * as Sentry from '@sentry/browser'

export default defineNuxtPlugin(() => {
    Sentry.init({
        dsn: 'https://2c31a0929ddc2925c03b8f91d24040e0@o4504170234183680.ingest.us.sentry.io/4508058538868736',
        environment: 'production',
        integrations: [],
        autoSessionTracking: false,
        tracesSampleRate: 0,
    })
})
